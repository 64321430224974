<template>
    <div @mousedown="resetAfterMockPayment" @keydown="resetAfterMockPayment" class="bg-grey-2">
        <!-- N/A notice
         <q-card v-if="availabilityBlocker" :class="mockHide ? 'bg-grey-2 text-grey-7 text-italic' : 'q-pa-lg bg-grey-2 text-h5 text-center'">
            <q-card-section v-if="availabilityBlocker === 'past'">
                {{ $t('storefront.not_for_sale_past_event') }}
            </q-card-section>
            <q-card-section v-else-if="availabilityBlocker === 'too_early'">
                {{ $t('storefront.not_for_sale_too_early') }}
                {{ mockHide ? `(Až od ${formatDate(getPublicFrom())}.)` : '' }}
            </q-card-section>
            <q-card-section v-else-if="availabilityBlocker === 'too_late'">
                {{ $t('storefront.not_for_sale_too_late') }}
                {{ mockHide ? `(Pouze do ${formatDate(getPublicTo())}.)` : '' }}
            </q-card-section>
            <q-card-section v-else-if="availabilityBlocker === 'sold_out'">
                {{ $t('storefront.all_sold_out') }}
            </q-card-section>
            <q-card-section v-else-if="mockHide && availabilityBlocker === 'not_ready'">
                {{ $t('storefront.not_ready_to_sell') }}
            </q-card-section>
            <q-card-section v-else>
                {{ $t('storefront.not_for_sale_generic') }}
            </q-card-section>
        </q-card>
        -->
        <!-- Main area -->
        <div v-if="uberEvent" class="col">
            <div class="flex justify-between items-center q-pa-md z-top bg-white" style="position: sticky; top:0; border-bottom: 1px solid #D4D4D4;">
                <div class="typography-h1">{{ $t('storefront.title') }}</div>
                <q-btn class="my-btn my-btn--secondary gt-sm" outline :label="$t('storefront.lang_other')" @click="toggleLanguage" />
                <q-btn class="my-btn my-btn--secondary lt-md" outline :label="$t('storefront.lang_other__mobile')" @click="toggleLanguage" />
            </div>
            <div v-if="ticketsBlocked" class="blocking-notification fields lt-md">
                <span class="body-medium text-white">
                    {{ $t('storefront.blocked_until') }}
                    <span class="text-red">{{ blockTimeRemaining }}</span>
                </span>
            </div>
            <!-- Language switcher -->

            <!-- Main heading -->

            <!-- (Uber-)event details  -->
            <div class="no-margin-sm q-px-md q-py-lg q-mx-md q-mt-md bg-white bordered-box">
               <div class="q-mb-md">
                    <UberEventChip :data="uberEvent" />
                </div>
                <span class="small-label">{{$t('label.event_title')}}</span>
                <div class="typography-h2 q-mb-sm">
                    {{ loc(uberEvent.name) }}

                    {{(event && loc(event.name)) && `- ${loc(event.name)}`}}
                </div>
                <div class="typography-h3 text-grey-5 q-mb-md" v-if="loc(uberEvent.subtitle)">{{ loc(uberEvent.subtitle) }}</div>
                <div class="flex">
                    <div class="q-mb-md">
                        <span class="small-label block q-mb-sm">{{ $t('label.location') }}</span>
                        <div class="description">{{ formattedVenue }}</div>
                    </div>
                    <div class="q-ml-lg q-mb-md" v-if="formattedDate">
                        <template v-if="event && formattedDate && uberEvent.type === 'divadlo'">
                            <input-special
                                :label="$t('label.event_date')"
                                :active-item="{
                                              label: formattedDate,
                                              value: formattedDate,
                                              className: 'my-special-input__option'
                                            }"
                               :select-options="eventDateOptions"
                               @input="dateChangeHandler"
                            />
                        </template>
                        <template v-else>
                            <span class="small-label block q-mb-sm">{{ $t('label.event_date') }}</span>
                            <div class="description">  {{ formattedDate }}</div>
                        </template>

                    </div>
                </div>
                <div v-if="uberEvent.promoterRef">
                    <span class="small-label block q-mb-sm q-pt-sm">{{ $t('label.organizer') }}</span>
                    <div class="description">  {{findById(promoters, uberEvent.promoterRef).title}}</div>
                </div>
            </div>

            <!-- Warning message -->
            <q-card-section v-if="warningMessage" class="q-mt-md text-orange text-subtitle1 text-bold text-italic bg-white" v-html="warningMessage" />

            <!-- Error message -->
            <q-card-section v-if="errorMessage" class="q-mt-md text-red text-subtitle1 text-bold text-italic bg-white" v-html="errorMessage" />

            <q-card-section class="bg-white q-mx-md no-margin-sm bordered-box">
                <q-timeline color="primary">
                    <!-- Tickets -->
                    <q-timeline-entry class="my-timeline" >
                        <template #title>
                            <span class="typography-h2">
                             {{$t('storefront.tickets')}}
                            </span>
                        </template>
                        <p class="q-mb-lg">{{ $t('label.tickets-choose') }}</p>
                        <!-- Event selection -->
                        <q-card flat :class="[{'card-multiple q-mb-lg' : eventSelections.length > 1}]" v-for="(es, esIdx) in eventSelections" :key="es.event ? es.event.id : 0">
                            <q-card-section :class="[es.event ? 'q-px-sm q-py-sm' : 'q-pa-none']">
                                <div >
                                    <div :class="{ 'text-grey': mockHide && es.event && !isEventPublic(es.event) }">
                                        <span class="typography-h3 q-mb-sm block" v-if="esIdx === 0 && eventSelections.length > 1 && uberEvent.type === 'festival' "> {{ loc(uberEvent.name) }}</span>
                                        <span class="typography-h3 q-mb-sm block">{{ es.event && getEventSelectionName(es.event) }}</span>
                                        <template v-if="mockHide && es.event && !isEventPublic(es.event)">
                                            <q-icon name="warning" color="warning" class="q-ml-xs" />
                                            {{ formatDateRange(getPublicFrom(es.event), getPublicTo(es.event)) }}
                                        </template>
                                    </div>
                                    <!-- Ticket selection -->
                                    <template v-for="(ts, tsIdx) in es.ticketSelections">
                                        <div class="row items-start q-col-gutter-lg tickets-selection"  v-if="hasAvailableSlots(ts)">
                                            <!-- Ticket group -->
                                            <div class="col-12 col-md-3">
                                                <q-select
                                                    popup-content-class="my-input-select__options"
                                                    class="my-input-select full-width col-md-4"
                                                    dropdown-icon="svguse:/icons.svg#carret-down--stroke"
                                                    input-debounce="0"
                                                    behavior="menu"
                                                    outlined v-model="ts.group"
                                                    :label="getTGSelectLabel(es)"
                                                    :bottom-slots="!!getTGHint(ts.group)" hide-bottom-space :disable="ticketsBlocked || orderPlaced || (mockHide && !getTicketGroups(es).length)"
                                                    :readonly="tgSelectReadonly(es)" :hide-dropdown-icon="tgSelectReadonly(es)"
                                                    :options="getTicketGroups(es)" option-value="id" :option-label="getTGOptionLabel"
                                                    :option-disable="o => !getTGAvailableCount(o)">
                                                    <template v-slot:hint>
                                                        <div class="row" style="margin-left: -8px;">
                                                            <q-icon name="info" class="q-mr-xs" />
                                                            <span class="col">{{ getTGHint(ts.group) }}</span>
                                                        </div>
                                                    </template>
                                                    <template v-slot:option="{ index, opt, selected, itemProps, itemEvents }">
                                                        <q-item clickable :key="index" v-bind="itemProps" v-on="itemEvents">
                                                            <q-item-section :class="{
                                                                    'text-italic': !getTGAvailableCount(opt),
                                                                    'text-grey': !selected && !isTGPublic(es, opt),
                                                                }">
                                                                <q-item-label>{{ getTGOptionLabel(opt) }}</q-item-label>
                                                                <q-item-label v-if="mockHide && !isTGPublic(es, opt)" caption class="row items-center">
                                                                    <q-icon name="warning" color="warning" class="q-mr-xs" />
                                                                    {{ formatDateRange(getTGPublicFrom(es, opt), getTGPublicTo(es, opt)) }}
                                                                </q-item-label>
                                                            </q-item-section>
                                                        </q-item>
                                                    </template>
                                              </q-select>
                                            </div>
                                            <!-- Discount -->
                                            <div class="col-12 col-md-3">
                                              <q-select
                                                popup-content-class="my-input-select__options"
                                                class="my-input-select full-width"
                                                dropdown-icon="svguse:/icons.svg#carret-down--stroke"
                                                input-debounce="0"
                                                behavior="menu"
                                                outlined
                                                emit-value map-options v-model="ts.discount" :label="$t('storefront.discount')"
                                                :disable="ticketsBlocked || orderPlaced || !ts.group || !getTGAvailableCount(ts.group) || !getDiscountOptions(ts.group).length"
                                                :options="getDiscountOptions(ts.group)" />
                                            </div>
                                            <!-- Ticket count -->
                                            <div class="col-12 col-md-3">
                                                <q-select
                                                popup-content-class="my-input-select__options"
                                                class="my-input-select full-width col-md-4"
                                                dropdown-icon="svguse:/icons.svg#carret-down--stroke"
                                                input-debounce="0"
                                                behavior="menu"
                                                outlined v-model="ts.count" :label="$t('storefront.count')"
                                                :disable="ticketsBlocked || orderPlaced || !ts.group || !getTGAvailableCount(ts.group) || isRowRedundant(es, ts)"
                                                :option-disable="o => !!o.length"
                                                :options="getCountOptions(es, ts.group, ts.count)">
                                                <template v-slot:option="{ index, opt, selected, itemProps, itemEvents }">
                                                    <q-item clickable :key="index" v-bind="itemProps" v-on="itemEvents">
                                                        <q-item-section :class="{ 'text-italic': opt.length }">
                                                            <q-item-label>{{ opt }}</q-item-label>
                                                        </q-item-section>
                                                    </q-item>
                                                </template>
                                            </q-select>
                                            </div>
                                            <div class="col-md-1 col-12 storefront-clear-btn" v-if="getCountOptions(es, ts.group, ts.count).length && ts.count && !ticketsBlocked && !orderPlaced">
                                               <q-icon @click="clearSelected(ts,es, esIdx, tsIdx)" size="20" class="block q-mt-lg q-ml-lg cursor-pointer gt-sm" color="gray" name="svguse:/icons.svg#trash"/>
                                                <q-btn  class="my-btn my-btn--secondary lt-md empty full-width no-shadow mobile-trash" @click="clearSelected(ts,es, esIdx, tsIdx)" size="20"  name="svguse:/icons.svg#trash">
                                                    <q-icon size="20" class="block" color="gray" name="svguse:/icons.svg#trash"/>
                                                </q-btn>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </q-card-section>
                        </q-card>

                        <!-- Total -->
                        <div class="q-mt-md">
                            <i18n path="storefront.total" tag="span">
                                <span class="body-medium" slot="count">{{ $tc('ticket', totalTicketCount) }}</span>
                                <span class="body-medium" slot="price">{{ $tc('price', formatThousands(totalPrice)) }}</span>
                            </i18n>
                        </div>

                        <!-- Next/back button -->
                        <div class="row fields">
                            <div class="q-mt-md fields col-12 col-md-3">
                                <q-btn
                                    class="my-btn my-btn--primary full-width" color="black"
                                   :label="$t(ticketsBlocked ? 'storefront.back' : 'storefront.next')"
                                   :disable="!readyToBlock || mockPaid" @click="ticketsBlocked ? releaseAndReturn() : blockAndProceed()" />
                            </div>
                        </div>

                        <!-- Block timer -->
                        <div v-if="ticketsBlocked" class="q-mt-md fields gt-sm">
                            {{ $t('storefront.tickets_blocked') }}<br>
                            <span class="text-subtitle1 text-bold">
                                {{ $t('storefront.blocked_until') }}
                                <span class="text-red">{{ blockTimeRemaining }}</span>
                            </span>
                        </div>
                    </q-timeline-entry>

                    <!-- Contact info -->
                    <q-timeline-entry class="my-timeline"  :color="ticketsBlocked ? null : 'grey'">
                        <template #title>
                            <span class="typography-h2" :class="{'text-grey-5':!ticketsBlocked}">
                                {{$t('storefront.contact_info')}}
                            </span>
                        </template>
                        <p class="q-mb-lg" :class="{'text-grey-5':!ticketsBlocked}">{{$t('label.contact-details')}}</p>

                        <div class="row q-col-gutter-md fields" :class="ticketsBlocked ? null : 'text-grey'">
                            <div class="col-12 col-md-4">
                                <q-input
                                    :rules="[val=>!!val || ''] "
                                    class="my-input col-6 required" outlined v-model="contactInfo.firstName" :label="$t('storefront.first_name')" name="firstName" :disable="!ticketsBlocked || orderPlaced" />
                            </div>
                            <div class="col-12 col-md-4">
                                <q-input
                                    :rules="[val=>!!val || ''] "
                                    class="my-input required col-6"  outlined v-model="contactInfo.lastName" :label="$t('storefront.last_name')" name="lastName" :disable="!ticketsBlocked || orderPlaced" />
                            </div>
                            <div class="col-1"></div>
                            <div class="col-12 col-md-4">
                                <q-input
                                    :rules="[val=>isEmail(val) || 'Zadejte platnou e-mailovou adresu'] "
                                    class="my-input required col-6" outlined v-model="contactInfo.email" :label="$t('storefront.email')" type="email" name="email" :disable="!ticketsBlocked || orderPlaced" />
                            </div>
                            <div class="col-12 col-md-4">
                                <q-input
                                    :rules="[val=>isPhone(val) || 'Zadejte platné telefonní číslo'] "
                                    class="my-input required col-6" outlined v-model="contactInfo.phone" :label="$t('storefront.phone')" type="tel" name="phone" :disable="!ticketsBlocked || orderPlaced" />
                            </div>
                        </div>
                    </q-timeline-entry>

                    <!-- Payment -->
                    <q-timeline-entry class="my-timeline" :color="paymentEnabled ? null : 'grey'">
                        <template #title>
                            <span class="typography-h2" :class="{'text-grey-5':!paymentEnabled}">
                                {{$t('storefront.payment')}}
                            </span>
                        </template>

                        <div class="" :class="paymentEnabled ? null : 'text-grey-5'">
                            <div>{{ $t('storefront.payment_desc') }}</div>
                            <div class="q-mt-md">
                                <q-checkbox class="my-checkbox" v-model="agreeToPromotions" value="false" :disable="!paymentEnabled" >
                                    <template #default>
                                        {{ $t('checkbox.agree-newsletter') }}
                                    </template>
                                </q-checkbox>
                            </div>
                            <div class="q-mt-md q-mb-lg">
                                <q-checkbox class="my-checkbox" :class="{error: agreeToTermsError}"  v-model="agreeToTerms" value="false" :disable="!paymentEnabled" >
                                    <template #default>
                                        <i18n path="checkbox.agree-terms" tag="p">
                                            <template v-slot:firm v-if="$i18n.locale==='en'">
                                                <span><a @click.s.stop="" target="_blank" href="http://www.meetfactory.cz/en/meetfactory/vseobecne_obchodni_podminky">MeetFactory</a></span>
                                            </template>
                                            <template v-slot:firm v-else >
                                                <span><a @click.s.stop="" target="_blank" href="http://www.meetfactory.cz/cs/meetfactory/vseobecne_obchodni_podminky">obchodními podmínkami MeetFactory</a></span>
                                            </template>
                                            <template v-slot:payment v-if="$i18n.locale==='en'">
                                                <a @click.s.stop="" target="_blank" href="https://static.payu.com/sites/terms/files/Obchodni_podminky_sluzby_PayU.pdf">PayU terms and conditions</a>
                                            </template>
                                            <template v-slot:payment v-else>
                                                <a @click.s.stop="" target="_blank" href="https://static.payu.com/sites/terms/files/Obchodni_podminky_sluzby_PayU.pdf">PayU</a>
                                            </template>

                                            <template v-slot:term v-if="$i18n.locale==='en'">
                                                <a @click.s.stop="" target="_blank" href="http://www.meetfactory.cz/en/meetfactory/vseobecne_obchodni_podminky">privacy policy</a>
                                            </template>
                                            <template v-slot:term v-else>
                                                <a @click.s.stop="" target="_blank" href="http://www.meetfactory.cz/cs/meetfactory/vseobecne_obchodni_podminky">zásady soukromí</a>
                                            </template>
                                        </i18n>
                                    </template>
                                </q-checkbox>
                            </div>
                            <div class="q-mt-md fields row">
                                <div class="col-12 col-md-3">
                                    <q-btn
                                        class="my-btn my-btn--primary full-width"
                                       color="black"
                                       :label="$t(mockPaid ? 'storefront.paid' : 'storefront.pay')"
                                       :disable="!paymentEnabled || mockPaid || !agreeToTerms" @click="placeOrderAndPay" />
                                </div>
                            </div>
                        </div>
                    </q-timeline-entry>

                    <q-timeline-entry class="hidden">
                    </q-timeline-entry>
                </q-timeline>
            </q-card-section>
        </div>

        <!-- Loading message -->
        <q-card v-if="!uberEvent && loading" class="q-pa-lg bg-grey-2 text-h4 text-center">
            <q-card-section>
                Načítám událost...
            </q-card-section>
        </q-card>

        <!-- Soft 404 -->
        <NotFound v-if="!uberEvent && !loading && !availabilityBlocker" />
        <div class="q-pb-xl gt-sm"></div>
    </div>
</template>

<script>
import InputSpecial from "../components/Input/InputSpecial/InputSpecial";
import Cookies from 'js-cookie';
import {sync} from 'vuex-pathify';

import Helpers from '@/mixins/Helpers';

export default {
    components: {
        InputSpecial
    },
    data() {
        return {
            agreeToPromotions: false,
            agreeToTerms: false,
            agreeToTermsError: false,
            loading: true,
            populating: false,
            uberEvent: null,
            event: null,
            ticketGroup: null,
            eventSelections: null,
            contactInfo: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
            },
            ticketsBlocked: false,
            orderPlaced: false,
            mockPaid: false,
            blockToken: null,
            blockTimer: null,
            blockTimeStart: null,
            blockTimeElapsedSecs: null,
            warningMessage: null,
            errorMessage: null,
            availabilityBlocker: null,
        };
    },

    computed: {
        ...sync([
            'eventTreeOpen',
        ]),
        eventDateOptions(){
            if(!this.event || !this.uberEvent){
                return false;
            }
            return this.uberEvent.events.map(event => {
                const date = this.formatDateRange(
                    (event.from),
                    (event.to),
                    false,
                    true,
                );
                return {
                    label: date,
                    value: {
                        from:event.from,
                        to:event.to,
                    },
                    class: 'my-special-input__option'
                }
            })

        },
        formattedVenue() {
            return this.loc(this.findById(this.venues, (this.event && this.event.venueRef) || this.uberEvent.venueRef).name);
        },
        formattedDate() {
            return this.formatDateRange(
                (this.event && this.event.from) || this.uberEvent.from,
                (this.event && this.event.to) || (!(this.event && this.event.from) && this.uberEvent.to),
                false,
                this.uberEvent.type !== 'festival' || this.event,
            );
        },
        showDateSelect() {
            return this.ueIs(this.uberEvent, 'multikoncert', 'divadlo') && this.event && this.uberEvent.events.length > 1;
        },
        listedEvents() {
            return !this.mockHide ?
                this.uberEvent.events :
                this.uberEvent.events.filter(e => !e.archived && e.isListed && !this.eSellBlocker(e, this.uberEvent));
        },
        totalTicketCount() {
            return _.sumBy(this.eventSelections, this.getESTicketCount);
        },
        totalPrice() {
            return _.sumBy(this.eventSelections, this.getESSubTotal);
        },
        blockTimeRemaining() {
            const time = appConfig.storefront.blockTimeoutSecs - this.blockTimeElapsedSecs;

            return `${Math.floor(time / 60)}:${(time % 60) < 10 ? '0' : ''}${time % 60}`;
        },
        readyToSell() {
            return (
                (!this.ticketGroup || !this.tgSellBlocker(this.ticketGroup, this.uberEvent, this.event)) &&
                (!this.event || !this.eSellBlocker(this.event, this.uberEvent)) &&
                !this.ueSellBlocker(this.uberEvent)
            );
        },
        readyToBlock() {
            return this.eventSelections && this.eventSelections.some(es => es.ticketSelections.some(ts => ts.count));
        },
        paymentEnabled() {
            return (
                this.ticketsBlocked &&
                this.contactInfo.firstName &&
                this.contactInfo.lastName &&
                this.contactInfo.email && this.isEmail(this.contactInfo.email) &&
                this.contactInfo.phone && this.isPhone(this.contactInfo.phone)
            );
        },
        devUiShown() {
            return appConfig.storefront.showDevUi && this.loggedIn;
        },
        mockHide() {
            return appConfig.storefront.mockHide || this.loggedIn;
        },
        blockTokenCookieName() {
            return `blockToken_${this.$route.params.token}`;
        },
    },

    watch: {
        agreeToTerms() {
            this.agreeToTermsError = !this.agreeToTerms;
        },
        eventSelections: {
            handler: function (val) {
                // Ignore changes made while populating from block token in cookie
                if (this.populating)
                    return;

                for (const es of val) {
                    const tss = es.ticketSelections;
                    const tgs = this.getTicketGroups(es);
                    const maxRowCount = _.sumBy(tgs, tg => this.getDiscountOptions(tg).length);
                    const extraTS = tss.find(ts => this.isRowRedundant(es, ts));
                    const reachedLimit = this.getESTicketCount(es) >= 10;
                    // Add new row when last existing row has count
                    if (tss.length < maxRowCount && _.last(tss).group && _.last(tss).count && !reachedLimit) {
                        tss.push({
                            group: (tgs.length === 1) ? tgs[0] : null,
                            discount: null,
                            count: null,
                        });
                    };

                    // Clear count of row made redundant by changing group or discount, and remove last row
                    if (extraTS && extraTS.count) {
                        extraTS.count = null;

                        tss.pop();
                    }
                }
            },
            deep: true,
        },
        readyToBlock(val) {
            if (!val && this.ticketsBlocked) {
                this.ticketsBlocked = false;
            }
        },
    },

    methods: {
        formatThousands(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },

        async dateChangeHandler(date){
            if(!this.formattedDate && !date.label && !date.value && ! date.value.from){
                return
            }
            if(date.label === this.formattedDate){
                return;
            }
            const link = this.uberEvent.events.find(e=> e.from === date.value.from && e.to === date.value.to);
            this.$router.push({
                name: 'storefront',
                params:{
                    token: link.linkToken
                }
            })
            this.$i18n.locale = this.$route.params.lang;
            axios.defaults.headers.common['X-Meet-Lang'] = this.$route.params.lang;

            if (!this.loggedIn || !this.uberEvents) {
                await this.fetchData();
            }

            this.init();

            const cookieToken = Cookies.get(this.blockTokenCookieName);

            if (cookieToken) {
                await this.populateFromBlockToken(cookieToken);
            }

        },
        clearSelected(targetTs,targetEs, esIdx, tsIdx) {
            if(targetEs.ticketSelections.length<2) {
                targetTs.count = 0;
                return;
            }
            if(!targetTs.count) {
                return
            }
            if(this.eventSelections.length === 1 && this.eventSelections[0].ticketSelections.length < 2) {
                targetTs.count = 0;
                return;
            }
            if(targetTs.count=== 10){
                targetTs.count = 0;
                return;
            }
            this.eventSelections[esIdx].ticketSelections.splice(tsIdx, 1);
        },
        hasAvailableSlots(es){
            let counter = 0;
            return true

        },
        getEventSelectionName(event) {
            const name = this.loc(event.name);

            switch (this.uberEvent.type) {
                case 'festival':
                case 'multikoncert':
                case 'divadlo':
                    return this.formatDate(event.from, false) + (name ? ` – ${name}` : '');
                case 'vystava':
                    return this.formatDate(event.from, false) + (name ? ` – ${name}` : '');
            }
        },
        tgSelectReadonly(es) {
            return this.getTicketGroups(es).length === 1;
        },
        isRowRedundant(eventSelection, ticketSelection) {
            for (const ts of eventSelection.ticketSelections) {
                if (ts === ticketSelection)
                    return false;

                if (ts.group === ticketSelection.group && ts.discount === ticketSelection.discount)
                    return true;
            }
        },
        getTicketGroups(eventSelection) {
            if (this.ticketGroup)
                return [this.ticketGroup];

            const ue = this.uberEvent;
            const e = eventSelection.event || this.event;
            const obj = e || ue;

            return !this.mockHide ?
                obj.ticketGroups :
                obj.ticketGroups.filter(tg => this.tgxx(tg, 'isListed') && (!tg.templateRef || tg.inherit) && !this.tgSellBlocker(tg, ue, e));
        },
        getESSubTotal(es) {
            return _.sumBy(es.ticketSelections, this.getTSSubTotal);
        },
        getTSSubTotal(ts) {
            return ts.count * this.getTicketSubTotal(ts);
        },
        getTicketSubTotal(ts) {
            if (!ts.count || !ts.group)
                return 0;

            const basePrice = this.tgxx(ts.group, 'price');
            const finalPrice = !ts.discount ? basePrice : ts.discount.replacementPrice || Math.round(basePrice * ts.discount.multiplier);

            return finalPrice;
        },
        getESTicketCount(es) {
            return _.sumBy(es.ticketSelections, ts => ts.count) || 0;
        },
        getTGSelectLabel(es) {
            const tgs = this.getTicketGroups(es);

            if (!tgs.length)
                return '-';

            const name = this.tgxx(tgs[0], tg => this.loc(tg.name));

            return tgs.length === 1 && !name ? this.$t('storefront.price') : this.$t('storefront.category');
        },
        getTGHint(ticketGroup) {
            return !ticketGroup ? null : this.loc(ticketGroup.description);
        },
        getTGOptionLabel(ticketGroup) {
            const name = this.tgxx(ticketGroup, tg => this.loc(tg.name));
            const price = this.tgxx(ticketGroup, 'price');
            const soldOutStr = this.getTGAvailableCount(ticketGroup) ? '' : ` (${this.$t('storefront.sold_out')})`;

            return (name ? `${name}: ` : '') + this.$tc('price', this.formatThousands(price)) + soldOutStr;
        },
        getTGPublicFrom(es, tg) {
            return this.tgPublicFrom(this.uberEvent, es.event || this.event, tg);
        },
        getTGPublicTo(es, tg) {
            return this.tgPublicTo(this.uberEvent, es.event || this.event, tg);
        },
        isTGPublic(es, tg) {
            return !this.mockHide || this.sfCheckPublicDates(this.uberEvent, es.event || this.event, tg);
        },
        getTGAvailableCount(tg) {
            return tg.availableCount || (this.tgxx(tg, 'ticketCount') - tg.blockedCount);
        },
        getTGTotalSelectedTicketCount(es, tg) {
            return _.sumBy(es.ticketSelections, ts => ts.group === tg && ts.count);
        },
        getCountOptions(es, tg, count) {
            if (!tg)
                return [];

            const totalMax = appConfig.storefront.maxTicketsPerSale;
            const maxFromTotalMax = totalMax - (this.totalTicketCount - count);
            const maxFromAvailable = this.getTGAvailableCount(tg) - (this.getTGTotalSelectedTicketCount(es, tg) - count);
            const max = Math.min(maxFromTotalMax, maxFromAvailable);
            const opts = _.range(max + 1);

            if (maxFromAvailable < totalMax) {
                opts.push(this.$t('storefront.only_few_tickets_remaining'));
            } else if (maxFromTotalMax < totalMax) {
                opts.push(this.$t('storefront.max_total_tickets_per_sale', { count: totalMax }));
            }
            return opts.filter(o => o > 0);
        },
        tgxx(ticketGroup, ...fieldsOrFns) {
            return this.tgx(ticketGroup, this.uberEvent, ...fieldsOrFns);
        },
        getDiscountOptions(ticketGroup) {
            const discounts = Helpers.methods.getDiscounts.call(this, ticketGroup, this.uberEvent);

            return [
                { value: null, label: this.$t('storefront.no_discount') },
                ...discounts.map(d => ({ value: d, label: `${this.loc(d.name)} (${this.getDiscountAmountStr(ticketGroup, d)})` })),
            ];
        },
        getDiscountAmountStr(ticketGroup, discount) {
            if (discount.replacementPrice)
                return `= ${this.$tc('price', discount.replacementPrice)}`;

            const basePrice = this.tgxx(ticketGroup, 'price');
            const finalPrice = Math.round(basePrice * discount.multiplier);
            const amount = finalPrice - basePrice;
            const sign = (amount > 0) ? '+' : '';

            return this.$tc('price', `${sign}${this.formatThousands(amount)}`);
        },
        goTo(linkToken) {
            if (linkToken) {
                this.$router.push(this.generatePath(linkToken));
            }
        },
        getPublicFrom(event) {
            event = event || this.event;

            return event ? this.ePublicFrom(this.uberEvent, event)
                         : this.uePublicFrom(this.uberEvent);
        },
        getPublicTo(event) {
            event = event || this.event;

            return event ? this.ePublicTo(this.uberEvent, event)
                         : this.uePublicTo(this.uberEvent);
        },
        isEventPublic(event) {
            return !this.mockHide || this.sfCheckPublicDates(this.uberEvent, event);
        },
        makeEventSelection(event) {
            return {
                event,
                ticketSelections: [{
                    group: null,
                    discount: null,
                    count: null,
                }],
            };
        },
        collectSelectedTickets() {
            const tickets = [];

            for (const es of this.eventSelections) {
                for (const ts of es.ticketSelections) {
                    if (!ts.group || !ts.count)
                        continue;

                    for (let i = 0; i < ts.count; ++i) {
                        const ticket = {
                            eventRef: (es.event && es.event.id) || (this.event && this.event.id),
                            ticketGroupRef: ts.group.id,
                            globalDiscountRef: ts.discount && ts.discount.isGlobal ? ts.discount.id : null,
                            localDiscountRef: ts.discount && !ts.discount.isGlobal ? ts.discount.id : null,
                        };

                        tickets.push(ticket);
                    }
                }
            }

            return tickets;
        },
        toggleLanguage() {
            this.$router.push({ params: { lang: this.$i18n.locale !== 'cs' ? 'cs' : 'en' } });
            this.$i18n.locale = this.$i18n.locale === 'en' ? 'cs' : 'en';
        },

        async blockAndProceed() {
            this.warningMessage = null;
            this.errorMessage = null;

            const tickets = this.collectSelectedTickets();

            try {
                this.blockToken = await this.apiBlockTickets(tickets);
            } catch (err) {
                this.$q.dialog({
                    class:'my-dialog error',
                    message: _.get(err, 'response.data.key') === 'api.block.notEnoughTicketsAvailable'
                        ? this.$t('storefront.tickets_no_longer_available')
                        : this.$t('storefront.ticket_blocking_failed'),
                }).onOk(this.refreshDataAndReset);

                return;
            }

            const expiryInDays = appConfig.storefront.blockTimeoutSecs / 3600 / 24;

            Cookies.set(this.blockTokenCookieName, this.blockToken, { expires: expiryInDays });

            this.startReleaseTimer(Date.now(), 0);
        },
        async releaseAndReturn() {
            this.warningMessage = null;
            this.errorMessage = null;

            clearTimeout(this.blockTimer);

            try {
                await this.apiReleaseTickets();
            } catch (err) {
                // Unexpected error, just reload
                window.location.reload();
            }

            this.blockToken = null;

            Cookies.remove(this.blockTokenCookieName);

            this.ticketsBlocked = false;
            this.orderPlaced = false;
            this.blockTimer = null;
        },
        async placeOrderAndPay() {
            let response;
            try {
                if (this.orderPlaced) {
                    // Retry failed payment
                    response = await axios.post('/retry', {
                        uberEventRef: this.uberEvent.id,
                        blockToken: this.blockToken,
                    });
                } else {
                    // Bump sale status and try payment for the first time
                    response = await axios.post('/order', {
                        uberEventRef: this.uberEvent.id,
                        blockToken: this.blockToken,
                        contactInfo: this.contactInfo,
                    });
                }
            } catch (err) {
                this.$q.dialog({
                    message: this.$t('storefront.place_order_failed'),
                }).onOk(() => window.location.reload());

                return;
            }

            if (appConfig.storefront.mockPay) {
                // Mock payment - confirm immediately

                this.ticketsBlocked = false;
                this.orderPlaced = true;
                this.mockPaid = true;
                this.blockToken = null;

                Cookies.remove(this.blockTokenCookieName);
            } else {
                // Real payment - redirect to gateway

                const redirectUri = response.data;

                window.location.href = redirectUri;
            }
        },

        async apiBlockTickets(tickets) {
            const response = await axios.post('/block', {
                uberEventRef: this.uberEvent.id,
                tickets,
            });

            return response.data;
        },
        async apiReleaseTickets() {
            await axios.post('/release', {
                uberEventRef: this.uberEvent.id,
                blockToken: this.blockToken,
            });
        },

        startReleaseTimer(startTimeMillis, elapsedSecs) {
            this.blockTimeStart = startTimeMillis;
            this.blockTimeElapsedSecs = elapsedSecs;
            this.ticketsBlocked = true;

            const countDown = () => {
                const elapsedMillis = Date.now() - this.blockTimeStart;
                const idealElapsedMillis = this.blockTimeElapsedSecs * 1000;
                const diff = elapsedMillis - idealElapsedMillis;

                this.blockTimeElapsedSecs += 1;

                if (this.blockTimeElapsedSecs === appConfig.storefront.blockTimeoutSecs) {
                    this.$q.dialog({
                        class:'my-dialog error',
                        title: this.$t('storefront.block_expired_title'),
                        message: this.$t('storefront.block_expired_text'),
                        ok: { label: this.$t('yes') },
                        cancel: { label: this.$t('cancel'), color: null, textColor: 'primary' },
                    }).onOk(async () => {
                        await this.releaseAndReturn();

                        this.blockAndProceed();
                    }).onCancel(async () => {
                        await this.releaseAndReturn();
                    });
                } else if (this.ticketsBlocked) {
                    this.blockTimer = setTimeout(countDown, 1000 - diff);
                } else {
                    this.blockTimer = null;
                }
            };

            countDown();
        },

        resetAfterMockPayment() {
            if (appConfig.storefront.mockPay && this.mockPaid) {
                this.orderPlaced = false;
                this.mockPaid = false;
            }
        },
        async refreshDataAndReset() {
            // Hack to force availability data refresh for admins
            if (this.loggedIn) {
                this.lastFetch.uberEvents = null;
            }

            await this.fetchData();

            this.init();
        },
        async fetchData() {
            if (this.loggedIn) {
                await this.fetchAll('settings');
                await this.fetchMany('uberEvents', 'venues', 'promoters', 'globalDiscounts');
            } else {
                await this.fetchPublicInfo(this.$route.params.token);
            }
        },
        init() {
            const token = this.$route.params.token;

            outer:
            for (const ue of this.uberEvents) {
                if (ue.linkToken === token) {
                    this.uberEvent = ue;

                    break outer;
                }

                for (const e of (ue.events || [])) {
                    if (e.linkToken === token) {
                        this.uberEvent = ue;
                        this.event = e;

                        break outer;
                    }

                    for (const tg of e.ticketGroups) {
                        if (tg.linkToken === token) {
                            this.uberEvent = ue;
                            this.event = e;
                            this.ticketGroup = tg;

                            break outer;
                        }
                    }
                }

                for (const tg of (ue.ticketGroups || [])) {
                    if (tg.linkToken === token) {
                        this.uberEvent = ue;
                        this.ticketGroup = tg;

                        break outer;
                    }
                }
            }

            // Mock-hide only: check if ready to sell
            if (this.mockHide && this.uberEvent && !this.readyToSell) {
                this.uberEvent = null;
                this.event = null;
                this.ticketGroup = null;
            }

            // Check if currently available
            if (this.uberEvents.length === 1 && this.uberEvents[0].availabilityBlocker) {
                this.availabilityBlocker = this.uberEvents[0].availabilityBlocker;
            } else if (this.mockHide) {
                this.availabilityBlocker = !this.uberEvent ? 'not_ready' : this.sfAvailabilityBlocker(this.uberEvent, this.event, this.ticketGroup);
            }

            // Terminate loading if no uber-event
            if (!this.uberEvent) {
                this.loading = false;
                return;
            }

            // Initialize ticket selections (inside event selections)
            if (!this.event && !this.ticketGroup && this.listedEvents.length) {
                // Uber-event with one or more listed events
                this.eventSelections = this.listedEvents.map(this.makeEventSelection);

                // Prepend uber-event ticket groups if any
                if ((this.uberEvent.ticketGroups || []).length) {
                    this.eventSelections.unshift(this.makeEventSelection(null));
                }
            } else {
                // Ticket group, event, or uber-event without listed events
                this.eventSelections = [this.makeEventSelection(null)];
            }

            // If there is only one ticket group in an event selection, pre-select it
            for (const es of this.eventSelections) {
                const tgs = this.getTicketGroups(es);

                if (tgs.length === 1) {
                    es.ticketSelections[0].group = tgs[0];
                }
            }

            this.loading = false;
        },
        async populateFromBlockToken(blockToken) {
            let sale;

            try {
                const response = await axios.get('/sale', { params: { blockToken } });

                sale = response.data;
            } catch (err) {
                // Error notification handled via interceptor
            }
            if (!sale)
                return;

            this.populating = true;

            switch (sale.status) {
                case 'paid':
                    const date = this.formattedDate || null
                    const seller = this.promoters && this.uberEvent.promoterRef && this.findById(this.promoters, this.uberEvent.promoterRef).title ? this.findById(this.promoters, this.uberEvent.promoterRef).title : null
                    const storeObj = {
                        type: this.uberEvent.type,
                        name: this.uberEvent.name,
                        subtitle: this.loc(this.uberEvent.subtitle) || null,
                        location : this.formattedVenue,
                        seller,
                        date
                    }
                    if(localStorage.getItem('successItem')){
                        localStorage.removeItem('successItem')
                    }
                    localStorage.setItem('successItem', JSON.stringify(storeObj) )
                    Cookies.remove(this.blockTokenCookieName);

                    this.$router.push({ name: 'paymentSuccess' });

                    break;

                case 'expired':
                    Cookies.remove(this.blockTokenCookieName);

                    this.warningMessage = this.$t('storefront.sale_expired');

                    break;

                case 'ordered':
                    this.blockToken = sale.blockToken;
                    this.ticketsBlocked = true;
                    this.orderPlaced = true;

                    const order = _.last(sale.payuData);

                    if (order && order.status === 'CANCELED') {
                        this.errorMessage = this.$t('storefront.payment_error');
                    }

                    if (order && order.status === 'PENDING') {
                        this.warningMessage = this.$t('storefront.payment_pending');

                        setTimeout(() => window.location.reload(), 15000);
                    }

                    break;

                case 'blocked':
                    this.blockToken = sale.blockToken;
                    this.ticketsBlocked = true;

                    break;
            }

            if (this.blockToken) {
                // Remove initial empty ticket selections
                // (except for event selections not included in sale)
                for (const es of this.eventSelections) {
                    if (!es.event && sale.tickets.find(t => !t.eventRef) ||
                        (es.event || this.event) && sale.tickets.find(t => t.eventRef === (es.event || this.event).id)) {

                        es.ticketSelections = [];
                    }
                }

                // Populate ticket selections from sale tickets
                for (const ticket of sale.tickets) {
                    const es = (this.event || this.ticketGroup)
                        ? this.eventSelections[0]
                        : this.eventSelections.find(s => (!s.event && !ticket.eventRef) || (s.event && s.event.id == ticket.eventRef));

                    let ts = es.ticketSelections.find(s => s.group && s.group.id === ticket.ticketGroupRef && (
                        (!s.discount && !ticket.globalDiscountRef && !ticket.localDiscountRef) ||
                        (s.discount && s.discount.isGlobal && s.discount.id === ticket.globalDiscountRef) ||
                        (s.discount && !s.discount.isGlobal && s.discount.id === ticket.localDiscountRef)
                    ));

                    if (ts) {
                        ts.count += 1;
                    } else {
                        const ue = this.uberEvent;
                        const groups = (ticket.eventRef ? this.findById(ue.events, ticket.eventRef) : ue).ticketGroups;

                        ts = {
                            group: this.findById(groups, ticket.ticketGroupRef),
                            discount:
                                ticket.globalDiscountRef ? this.findById(this.globalDiscounts, ticket.globalDiscountRef) :
                                ticket.localDiscountRef ? this.findById(ue.localDiscounts, ticket.localDiscountRef) : null,
                            count: 1,
                        };

                        es.ticketSelections.push(ts);
                    }
                }

                if (sale.contactInfo) {
                    this.contactInfo = sale.contactInfo;
                }

                this.startReleaseTimer(sale.blocked, dayjs().diff(sale.blocked, 'second'));
            }

            this.populating = false;
        },
    },

    @cancellableFetch
    async created() {
        this.$i18n.locale = this.$route.params.lang;
        axios.defaults.headers.common['X-Meet-Lang'] = this.$route.params.lang;

        if (!this.loggedIn || !this.uberEvents) {
            await this.fetchData();
        }

        this.init();

        const cookieToken = Cookies.get(this.blockTokenCookieName);

        if (cookieToken) {
            await this.populateFromBlockToken(cookieToken);
        }
    },

    meta() {
        return {
            title: this.event ? this.loc(this.event.name) : this.uberEvent ? this.loc(this.uberEvent.name) : this.$t('storefront.title'),
        };
    },
    mounted() {
        // setTimeout(() => {
        //     this.ticketsBlocked = true;
        // }, 1110);

        //
    }
};
</script>

<style lang="scss" scoped>
.fields {
    max-width: 960px;
}

.tg-non-public .q-field__native {
    color: $grey;
}

.tree-wrapper {
    &.closed {
        width: 84px;
    }
}

.tree-scroller {
    max-height: calc(100vh - 180px);
    overflow-y: scroll;
}
.tickets-selection + .tickets-selection{
    margin-top: 16px;
    @media screen and (max-width: $breakpoint-xs) {
        margin-top: 32px;
        padding-top: 32px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: calc(100% + 24px);
            height: 1px;
            background-color: $black-200;
        }
   }
}
.storefront-clear-btn{
    transition: all .2s ease-in-out;
    opacity: 0.6;
    &:hover{
        opacity:1;
    }
}
.card-multiple{
    background: $black-100;
    border-radius: 0;
    padding: 24px 24px 40px;
    border: 1px solid $black-300;
}

.bordered-box{
    border: 1px solid $black-300;
    overflow-x: hidden;
}
.bordered-box + .bordered-box{
    border-top: none;
}
.blocking-notification{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 8000;
    padding: 8px 16px;
    background: $black-900;
}

</style>
